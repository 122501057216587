import http from "../modules/http.index"
import { Form } from "vform"
const state = () => ({
    loading: false,
    documentTypes: [],
    documentTypesForm: new Form({
        id: '',
        description: "",
        default: false,
        status: false,
        amount: 0.0
    }),
    headers: [
        {
            text: "Id",
            align: "start",
            filterable: false,
            value: "id",
        },
        { text: "Desc", value: "description" },
        { text: "Amount", value: "amount" },
        { text: "Is Default", value: "default" },
        { text: "Status", value: "status" },
        { text: "Action", value: 'actions', sortable: false, },

    ],
})

const getters = {}
const mutations = {
    setDocumentTypes: (state, payload) => state.documentTypes = payload
}
const actions = {
    async getDocumentTypes(ctx) {
        return new Promise((resolve, reject) => {
            ctx.state.loading = true
            http
                .get(`/Setups/DocumentTypes/${ctx.rootState.site_id}`)
                .then((res) => {
                    if (res.status === 200) {
                        ctx.commit("setDocumentTypes", res.data.Data)
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
