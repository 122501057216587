<template>
    <div class="is-loading d-flex justify-content-center align-items-center">
        <!-- <img width="50px" class="animate-flicker" :src="loading_gif"  alt="Loading..."> -->
        <span  class=""><svg width="65" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="#4CAF50" class="w-full h-full"><g fill="none" fill-rule="evenodd" stroke-width="4"><circle cx="22" cy="22" r="1"><animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"></animate><animate attributeName="stroke-opacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"></animate></circle><circle cx="22" cy="22" r="1"><animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"></animate><animate attributeName="stroke-opacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"></animate></circle></g></svg></span>
    </div>
</template>


<script>
import loading_gif from "../assets/images/logo.png";
export default {
    name: "BaseLoading",
    data() {
      return {
        loading_gif: loading_gif
      }
    }
}
</script>
<style lang="scss">
@keyframes flickerAnimation {
  0%   { 
    opacity:0.2;
    transform: scale(0.2)
  }
  
  100% { 
    opacity:1;
    transform: scale(1.2);  
  }
}
@-o-keyframes flickerAnimation{
  0%   { 
    opacity:.4;
    transform: scale(0.2)
  }
  
  100% { 
    opacity:1;
    transform: scale(1.2);  
  }
}
@-moz-keyframes flickerAnimation{
  0%   { 
    opacity:0.2;
    transform: scale(0.2)
  }
  
  100% { 
    opacity:1;
    transform: scale(1.2);  
  }
}
@-webkit-keyframes flickerAnimation{
  0%   { 
    opacity:0.2;
    transform: scale(0.2)
  }
  
  100% { 
    opacity:1;
    transform: scale(1.2);  
  }
}
.animate-flicker {
   -webkit-animation: flickerAnimation 1s infinite;
   -moz-animation: flickerAnimation 1s infinite;
   -o-animation: flickerAnimation 1s infinite;
    animation: flickerAnimation 1s infinite;
}
</style>