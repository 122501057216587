import Vue from 'vue'
import moment from 'moment'

Vue.filter('parseDate', (mydate) => {
  return moment(mydate).format("DD-MM-yyyy h:mm a");
})

Vue.filter('parseUrgency', (description) => {
    let desc = parseInt(description)
    let days = desc / 24;
    var desc_name = ""
    if (desc < 24){
      desc_name = `${desc} Hours`
      if (desc === 1) {
        desc_name = `${desc} Hour`            
      }
    } else {
      desc_name = `${days} Days` 
      if (days === 1) {
        desc_name = `${days} Day`
      }
    }
    return desc_name
}) 