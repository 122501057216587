import http from "../modules/http.index"
import { Form } from "vform"
const state = () => ({
    loading: false,
    connections: [],
    connectionsForm: new Form({
        id: '',
        smtp_server: '',
        smtp_port: 456,
        smtp_user: '',
        smtp_password: '',
        ftp_user: '',
        ftp_server: '',
        ftp_password: '',
        ftp_dir: ''
    }),
    headers: [
        { 
            text: "Id",
            align: "start",
            filterable: false,
            value: "id",
        },
        { text: "SMTP Server", value: "smtp_server" },
        { text: "SMTP Port", value: "smtp_port" },
        { text: "SMTP User", value: "smtp_user" },
        { text: "FTP User", value: "ftp_user" },
        { text: "FTP Server", value: "ftp_server" },
        { text: "FTP Dir", value: "ftp_dir" },
        { text: "Action", value: 'actions', sortable: false, },
    ],
})

const getters = {}
const mutations = {
    setConnections: (state, payload) => state.connections = payload
}
const actions = {
    async getConnections(ctx) { // payload is the site id
        console.log("ctx",ctx.rootState.site_id);
        return new Promise((resolve, reject) => {
            ctx.state.loading = true
            http
                .get(`/Setups/Connections/${ctx.rootState.site_id}`) // pass payload as id to get a levels for a single client
                .then((res) => {
                    if (res.status === 200) {
                        ctx.commit("setConnections", res.data.Data.connections)
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}