import http from '../modules/http.index'
// import Form from 'vform'

const state = () => ({
    // sendNotificationUrl: `${process.env.VUE_APP_API_BASE_URL}/Notification/Messages/`,
    notifications: [],
    notification: {},
    unreadNotification: '',
    loading: false,
    // messageForm: new Form({
    //     receiver_id: "-1",
    //     message: ""
    // })
    newsTableAdmin: [
        {
            text: "News Id",
            align: "start",
            value: "id",
        },
        {
            text: "Sent By",
            align: "start",
            value: "sender",
        },
        {
            text: "Status",
            align: "start",
            value: "is_read",
        },
        {
            text: "Actions",
            align: "start",
            value: "actions",
        },
        
    ],
})
const getters = {}


const mutations = {
    setNotifications: (state, payload) => state.notifications = payload.notifications,
    setNotification: (state, payload) => state.notification = payload.notification,
    setUnreadNotification: (state, payload) => state.unreadNotification = payload.no_unread

}


const actions = {
    async _getAllNot(ctx) {
        return new Promise((resolve, reject) => {
            ctx.state.loading = true;
            http
                .get(`/Notifications/News`) // payload = order_id
                .then((res) => {
                    console.log("notifications", res);
                    if (res.status === 200) {
                        ctx.commit("setNotifications", res.data.Data)
                        resolve(res)
                    }
                    else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        })
    },
     
    async _getNot(ctx, payload) {
      return new Promise((resolve, reject) => {
        http
            .get(`/Notifications/SingleNews/${payload}`) // payload = order_id
            .then((res) => {
                if (res.status === 200) {
                    ctx.commit("setNotification", res.data.Data)
                    resolve(res)
                }
                else reject(res)
            })
            .catch((err) => {
                console.log("err", err);
                reject(err)
            })
      })  
    },

    async _getUnreadCount(ctx) {
        return new Promise((resolve, reject) => {
          http
              .get(`/Notifications/NoOfUnreadNotifications`) // payload = order_id
              .then((res) => {
                  if (res.status === 200) {
                      ctx.commit("setUnreadNotification", res.data.Data)
                      resolve(res)
                  }
                  else reject(res)
              })
              .catch((err) => {
                  console.log("err", err);
                  reject(err)
              })
        })  
      },
    // async _sendNot(ctx, payload) {
    //     return new Promise((resolve, reject) => {
    //         payload.messageForm
    //             .post(ctx.state.sendNotificationUrl + payload.order_id)
    //             .then((res) => {
    //                 if (res.status === 200) {
    //                     ctx.dispatch('_getAllNot', payload.order_id) // refresh messages after sending a message
    //                     resolve(res)
    //                 } 
    //                 else reject(res) 
    //             })
    //             .catch((err) => reject(err))
    //     })
    // }
}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}