import { Form } from 'vform'
import http from '../modules/http.index'
const state = () => ({
    loading: false,
    clients: [],
    client: {},
    clientsForm: new Form({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        country: '',
        password: '',
        site_id: ''
    }),
    headers: [
        {
            text: "Id",
            align: "start",
            filterable: false,
            value: "id",
        },
        { text: "First", value: "first_name" },
        { text: "Last", value: "last_name" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Status", value: "deleted" },
        { text: "Action", value: 'actions', sortable: false, },
    ],
})

const getters = {

}
const mutations = {
    setClients: (state, payload) => state.clients = payload,
    setClient: (state, payload) => state.client = payload
}
const actions = {
    async getClients(ctx) {
        return new Promise((resolve, reject) => {
            ctx.state.loading = true
            http
                .get(`/Sites/Clients`)
                .then((res) => {
                    if (res.status === 200) {
                        ctx.commit("setClients", res.data.Data)
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        })
    },

    async getClient(ctx, payload) {
        return new Promise((resolve, reject) => {
            try {
                ctx.commit("setClient", ctx.state.clients.find(client => client.id === payload))
                resolve(true)
            } catch (err) {
                console.log(err);
                reject(err)
            }
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}