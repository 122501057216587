<template>
    <div ref="actionsModal" class="modal fade" :id="modalId" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title font-weight-bold text-dark" id="exampleModalLabel">{{ title || '' }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <BaseLoading v-if="loading"></BaseLoading>
                <slot v-else></slot>
            </div>
           
            </div>
        </div>
    </div>

    <!-- <v-dialog
        v-model="$store.state.showModal"
        class="text-left"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card>
          <v-card-title class="text-h5">
            {{ title || '' }}
          </v-card-title>
          <v-card-text>
            <BaseLoading v-if="loading"></BaseLoading>
             <slot v-else></slot>
          </v-card-text>
        </v-card>
      </v-dialog> -->

</template>


<script>
import { mapState } from "vuex";
export default {
    name: "BaseModal",
    props: {
        title: {
            type: String,
            default: ""
        },
        modalId: {
            type: String,
            default: "exampleModal"
        }
    },
    computed: {
        ...mapState("order", ["loading"])
    }
}
</script>
<style lang="scss" scoped>
    .overlay-loading {
        display: flex;
        flex-direction: column;
        position: inherit !important;
        z-index: 1;
        justify-content: center;
        height: inherit !important;
        width: inherit !important;
    }
    .modal-body{
        min-height:200px !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
</style>