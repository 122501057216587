import http from '../modules/http.index'


const state = () => ({
    editors: [],
    loading: false,
    headers: [
        {
            text: "Id",
            align: "start",
            filterable: false,
            value: "id",
        },
        { text: "Name", value: "names" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Country", value: "country" },
        { text: "Status", value: "status" },
        { text: "Rating", value: "rating" },
        { text: "Action", value: 'actions', sortable: false, },
    ],
})


const getters = {}
const mutations = {
    setEditors : (state, payload) => state.editors = payload,
}
const actions = {
    async getEditors(ctx) {
        return new Promise((resolve, reject) => {
            ctx.state.loading = true
            http
                .get(`/Writers/Editors/`)
                .then((res) => {
                    if (res.status === 200) {
                        ctx.commit("setEditors", res.data.Data)
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        })
    },

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}