<template>
  <div class=" body" :class="showSidebar ? 'sidebar-xs' : ''" >
    <!-- :class="showSidebarMobile ? 'sidebar-xs sidebar-mobile-main' : ''" -->

    <!-- Page content -->
    <div  class="page-content">
      <BaseSidebar></BaseSidebar>

      <!-- Main content -->
      <v-app style="height: 100vh!important" class="content-wrapper">

        <!-- <BaseNewnav></BaseNewnav> -->
        <BaseNavbar></BaseNavbar>

        <!-- Content area -->
        <div  class="content" >

          <!-- <BaseLoading style="min-height: 400px;height: 90vh;" v-if="$store.state.loading"></BaseLoading> -->
          <transition name="slide-fade">
            <router-view></router-view>
          </transition>
        </div>
        <!-- /content area -->

        <!-- <BaseFooter></BaseFooter> -->
      </v-app>
      <!-- /main content -->
    </div>
    <!-- /page content -->
  </div>
</template>

<script>
import { mapState} from "vuex";
export default {
  name: "Home",

  computed: {
    ...mapState("sidebar", ["showSidebar"])
  },
};
</script>

<style lang="scss">
// sidebar
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(100px);
  opacity: 0;
}
.v-data-table__wrapper table thead *{
  background-color: #fff !important;
}
.v-data-table__wrapper table tr:nth-of-type(odd) {
  background-color: #f0f0f0;
}
</style>
