import http from "../modules/http.index"
import { Form } from "vform"
const state = () => ({
    loading: false,
    WriterPreferences: [],
    WriterPreferencesForm: new Form({
        id: '',
        desc: "",
        title: "",
        cost: "",
    }),
    headers: [
        {
            text: "Id",
            align: "start",
            filterable: false,
            value: "id",
        },
        { text: "Title", value: "title" },
        { text: "Desc", value: "desc" },
        { text: "Amount", value: "cost" },
        { text: "Action", value: 'actions', sortable: false, },

    ],
})

const getters = {}
const mutations = {
    setWriterPreferences: (state, payload) => state.WriterPreferences = payload
}
const actions = {
    async getWriterPreferences(ctx) {
        return new Promise((resolve, reject) => {
            ctx.state.loading = true
            http
                .get(`/Setups/FetchWriterPreferences/${ctx.rootState.site_id}`)
                .then((res) => {
                    if (res.status === 200) {
                        ctx.commit("setWriterPreferences", res.data.Data)
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
