<template>
    <div class="overlay-loading">
        <!-- <img :src="loading_spinner" width="60px" alt=""> -->
        <span  class=""><svg width="65" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="#4CAF50" class="w-full h-full"><g fill="none" fill-rule="evenodd" stroke-width="4"><circle cx="22" cy="22" r="1"><animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"></animate><animate attributeName="stroke-opacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"></animate></circle><circle cx="22" cy="22" r="1"><animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"></animate><animate attributeName="stroke-opacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"></animate></circle></g></svg></span>
    </div>
</template>


<script>
import loading_spinner from "../assets/global_assets/images/loading.gif";
export default {
    name: "BaseOverlay",
    data() {
      return {
        loading_spinner: loading_spinner
      }
    }
}
</script>

<style lang="scss" scoped>
.overlay-loading {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 1;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
</style>
