import http from '../modules/http.index'

const state = () => ({
    clients: [],
    filters: {},
    loading: false,
    headers: [
        {
            text: "Id",
            align: "start",
            filterable: false,
            value: "id",
        },
        { text: "Name", value: "names" },
        
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Country", value: "country" },
        { text: "Site", value: "url" },
        { text: "Status", value: "deleted" },
        { text: "Action", value: 'actions', sortable: false, },
    ],
})

const mutations = {
    setClients: (state, payload) => {
        state.clients = payload.Data.clients
        state.filters = payload.Data.filters
    },
    setLoading: (state, payload) => state.loading = payload
}

const actions = {
    async _getClients(ctx) {
        return new Promise((resolve, reject) => {
            ctx.commit("setLoading", true)
            http
                .get(`/Users/Clients/`)
                .then((res) => {
                    if (res.status === 200) {
                        ctx.commit("setClients", res.data)
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.commit("setLoading", false))
        })
    },

    async _navigate(ctx, payload) {
        return new Promise((resolve, reject) => {
            ctx.commit("setLoading", true)
            http
                .get(payload) // payload = order status e.g pending, inprogress
                .then((res) => {
                    if (res.status === 200) {
                        ctx.commit("setClients", res.data)
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.commit("setLoading", false))

        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}