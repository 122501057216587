const Alerts = {
    methods: {
        successNoti(msg) {
            let message = "success"
            if (msg && msg.data) {
                message = msg.data.message
            } else if (msg && msg.data == "") {
                message = "Success!"
            } else {
                message = msg
            }
            this.$notify({
                title: "Success",
                text: message,
                style: "success",
            });
        },

        errorNoti(msg) {
            console.log('msg.response', msg.response);
            this.errors = msg.response.data;
            let message = ""
            if (msg.response.data.Message) {
                message = msg.response.data.Message
            } else if (msg.response === undefined) {
                message = msg
            } else if (msg.response.data.Message === undefined) {
                message = msg
            } else {
                message = msg.response.Message
            }

            this.$notify({
                title: "Error",
                text: message,
                style: "danger",
            });

            console.log('message', message);

        }
    }
}

export default Alerts