<template>
  <!-- Main navbar -->
  <div class="navbar py-sm-3 py-2 d-flex align-items-center  navbar-expand-md navbar-light ">
    <div class="navbar-brand d-sm-none d-flex mr-0">
      <h1 class=" font-weight-bolder text-dark  ">
      {{site_settings.name }}
      </h1>
    </div>

    <div class="d-md-none">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbar-mobile"
      >
        <i class="icon-tree5"></i>
      </button>
      <button
        @click="_toggleSidebarMobile"
        class="navbar-toggler sidebar-mobile-main-toggle"
        type="button"
      >
        <i class="is-icon-toggle" :class="'icon-paragraph-justify3'"></i>
      </button>
    </div>

    <div class="collapse navbar-collapse" id="navbar-mobile">
      
      <ul class="navbar-nav is-search">
        <form v-if="userType == userTypes.admin  || userType == userTypes.editor || userType == userTypes.client"  @submit.prevent="doSearch">
          <!-- <v-text-field
            placeholder="Search.."
            class="border-success"
            v-model="search"
            hide-details
            outlined
            dense
          >
            <v-icon @click="doSearch" slot="append" color="green"
              >mdi-magnify</v-icon>
          </v-text-field> -->
          <div style=" border-radius: 50px!important;" class="border d-flex px-4 py-2 flex-row w-sm-50 w-100  my-1 border-primary ">
                <input v-model="search"  id="input-65" class="w-100  custom-search  " placeholder="Search.." type="text">
                <v-btn
                  elevation="0"
                  @click="doSearch"
                  fab
                  x-small
                  class="search_btn"
                >
              <!-- <v-icon small color="primary">mdi-magnify</v-icon> -->
              <v-icon  name="search" scale="1"/>

            </v-btn>
                
          </div>
        </form>
      </ul>

      <ul class="navbar-nav ml-auto">
        <li  v-if="(userType == userTypes.admin  || userType == userTypes.client) && user.id != 100000" class="nav-item dropdown dropdown-user">
          <a
            href="#"
            class="navbar-nav-link d-flex align-items-center dropdown-toggle"
            data-toggle="dropdown"
          >
            <span class="font-weight-bold " >
              Setups
            </span>
          </a>

          <div class="dropdown-menu dropdown-menu-right">

            <router-link v-for="(item, index) in setupsMenu" :key="index + 'setups'"  :to="{ name: item.routeName }" class="dropdown-item  dropdown_setups"
              >
              <!-- <i :class="item.icon"></i>  -->
              <v-icon scale="2" :name="item.icon"  />
              {{item.text}}
              </router-link
            >
            
          </div>
        </li>
        <li v-if="user.id != 100000 " class="nav-item dropdown dropdown-notifications">
          <a href="#" class="navbar-nav-link" data-toggle="dropdown">
            <i class="icon-bell2"></i>
            <span class="d-md-none ml-2">Notifications</span>
            <span class="badge badge-mark border-primary ml-auto ml-md-0"></span>
          </a>

          <div class="dropdown-menu dropdown-menu-right">
            <span v-if="notifications && notifications.length > 0">
              <router-link v-for="(notification, index) in notifications" :key="index" :to="{ name: 'Profile' }" class="dropdown-item"
                >
                <i class="icon-bell2 text-success"></i>
                {{notification}}
              </router-link>
            </span>
          </div>
        </li>

        <li v-if="user.id != 100000 " class="nav-item dropdown dropdown-notifications">
          <router-link :to="{ name: 'Messages' }" class="navbar-nav-link" data-toggle="dropdown">
            <!-- <i class="icon-envelope"></i> -->
            <v-icon scale="1.3" name="envelope" color="black" />

            <span class="d-md-none ml-2">Messages</span>
          </router-link>
        </li>

        <li class="nav-item dropdown dropdown-user">
          <a
            href="#"
            class="navbar-nav-link d-flex align-items-center dropdown-toggle"
            data-toggle="dropdown"
          >
            <img
              src="../assets/global_assets/images/image.png"
              class="rounded-circle mr-2"
              height="32"
              alt=""
            />
            <span class="font-weight-bold ">
              {{ user.first_name || user.last_name }}
            </span>
          </a>

          <div class="dropdown-menu dropdown-menu-right">
            <router-link :to="{ name: 'Profile' }" class="dropdown-item"
              ><i class="icon-user-plus"></i> My profile</router-link
            >
            <router-link v-if="user.id != 100000 " :to="{ name: 'Balance' }" class="dropdown-item"
              ><i class="icon-coins"></i> My balance</router-link
            >
            <router-link v-if="user.id != 100000 " :to="{ name: 'Payments' }" class="dropdown-item"
              ><i class="icon-comment-discussion"></i> Payments
              <!-- <span class="badge badge-pill bg-blue ml-auto">58</span> -->
            </router-link>
            <div class="dropdown-divider"></div>
           
            <a @click="doLogout" class="dropdown-item"
              ><i class="icon-switch2"></i> Logout</a
            >
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!-- /main navbar -->
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import Alerts from "../../mixins/alerts";
import {setupsMenu
} from "./sidebar.model";
export default {
  name: "Navbar",
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("sidebar", ["showSidebarMobile"]),
    ...mapState("setups", ["site_settings"]),
    ...mapGetters("auth", ["userType", "userTypes"]),

  },
  data() {
    return {
      search: "",
      notifications: [],
      setupsMenu: setupsMenu,
    };
  },
  methods: {
    ...mapActions("auth", ["doLogout"]),
    ...mapActions("setups", ["getSiteSettings"]),


    ...mapMutations("sidebar", ["_toggleSidebar", "_toggleSidebarMobile"]),
    doSearch() {
      document.location.href = `/home/search?search=${this.search}`;
    },

    getNotifications() {
      this.$req
        .apiGet("/Notifications/Dummy")
        .then(({data}) => {
          this.notifications = data.Data;
        })
        .catch((err) => console.log("noti error =====>", err));
    },
  },
  mounted() {
    this.search = this.$route.query.search;
    this.getNotifications();
    this.getSiteSettings()

  },
  mixins: [Alerts],
};
</script>

<style lang="scss" scoped>
.is-icon-toggle {
  transition: 0.3s all ease-in-out;
}
.is-search {
  width: 100%;
  form {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .is-search {
    width: 100% !important;
    margin-bottom: 20px;
    margin-top: 20px;
    form {
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 480px) {
  .is-search {
    width: 100% !important;
    margin-bottom: 20px;
    margin-top: 20px;
    form {
      width: 100% !important;
      // margin-top: 12px;
    }
  }
}
</style>
