import http from '../modules/http.index'

const state = () => ({
    setups: [],
    countries: [],
    site_settings: {}

})

const getters = {
    academic_levels: (state) => {
        return state.setups.academic_levels || {}
    },
    document_types: (state) => {
        return state.setups.document_types || {}
    },
    language_styles: (state) => {
        return state.setups.language_styles || {}
    },
    number_of_pages: (state) => {
        return state.setups.number_of_pages || {}
    },
    spacing: (state) => {
        return state.setups.spacing || {}
    },
    styles: (state) => {
        return state.setups.styles || {}
    },
    subject_areas: (state) => {
        return state.setups.subject_areas || {}
    },
    urgency: (state) => {
        return state.setups.urgency || {}
    },
    writer_levels: (state) => {
        return state.setups.writer_levels || {}
    },
    additional_services: (state) => {
        return state.setups.additional_services || {}
    },
    defaults: (state) => {
        return state.setups.defaults[0] || {}
    }
}

const mutations = {
    setSetups: (state, payload) => state.setups = payload,
    setCountries: (state, payload) => state.countries = payload,
    setSiteSettings: (state, payload) => state.site_settings = payload,

}


const actions = {
    async getSetups (ctx) {
        console.log("Base state? ", ctx.rootState.loading);
        return new Promise((resolve, reject) => {
            http.get('/Setups/Get/').then((res) => {
                if (res.status === 200) {
                    ctx.commit("setSetups", res.data.Data);
                    resolve(res)
                } else reject(res)
            })
            .catch((err) => reject(err))
            .finally(() => {
                ctx.commit("isLoading", false, { root: true})
                console.log("Base state? ", ctx.rootState.loading);
            })
        })
    },

    async getCountries(ctx) {
        return new Promise((resolve, reject) => {
            http.get('http://restcountries.eu/rest/v2/all')
                .then(res => {
                    console.log(res);
                    if (res.statusCode === 200) {
                        ctx.commit('setCountries', res.data)
                        resolve(res);
                    } else reject(res)
                })
                .catch((err) => {
                    console.log(err);
                    reject(err)
                })
        })
    },

    
    async getSiteSettings (ctx) {
        return new Promise((resolve, reject) => {
            http.get('/Sites/Info').then((res) => {
                if (res.status === 200) {
                    ctx.commit("setSiteSettings", res.data.Data.site);
                    resolve(res)
                } else reject(res)
            })
            .catch((err) => {
                reject(err)
            })
            .finally(() => {
                ctx.commit("isLoading", false, { root: true})
            })
        })
    },

    
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}