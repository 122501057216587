import http from "../modules/http.index"
import { Form } from "vform"
const state = () => ({
    loading: false,
    fileTypes: [],
    fileTypesForm: new Form({
        id: '',
        types: '',
        type: ''
    }),

})

const getters = {}
const mutations = {
    setFileTypes: (state, payload) => state.fileTypes = payload
}
const actions = {
    async getFileTypes(ctx) { // payload is the site id
        console.log("ctx",ctx.rootState.site_id);
        return new Promise((resolve, reject) => {
            ctx.state.loading = true
            http
                .get(`/Setups/FileTypes/${ctx.rootState.site_id}`) // pass payload as id to get a levels for a single client
                .then((res) => {
                    if (res.status === 200) {
                        ctx.commit("setFileTypes", res.data.Data)
                        resolve(res)
                    } else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}